import { DestroyRef, inject, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SchoolsQuery } from '@memberspot/admin/shared/data-access/schools';
import { CURRENT_DPA_VERSION } from '@memberspot/shared/util/constants';
import { distinctUntilChanged } from 'rxjs';

import { BannerService } from '../banner.service';

@Injectable({
  providedIn: 'root',
})
export class DpaBannerService {
  private _destroyRef = inject(DestroyRef);
  private _bannerService = inject(BannerService);
  private _lastSownSchoolId?: string;

  school$ = inject(SchoolsQuery).selectActive();

  /**
   * Show the banner only when the DPA version is outdated.
   *
   * That means, the school has not accepted the latest DPA version, but an older version.
   * Therefor, we must check for the old {@link School.dpa}, in addition to the new {@link School.dpaVersion} and the latest {@link CURRENT_DPA_VERSION}.
   */
  init() {
    this.school$
      .pipe(
        distinctUntilChanged((prev, curr) => prev?.id === curr?.id),
        takeUntilDestroyed(this._destroyRef),
      )
      .subscribe((school) => {
        if (!school) {
          if (this._lastSownSchoolId) {
            this._bannerService.closeBanner('dpa-' + this._lastSownSchoolId);
          }

          return;
        }

        if (school?.dpa && school?.dpaVersion !== CURRENT_DPA_VERSION) {
          this._showDpaBanner(school.id);
          this._lastSownSchoolId = school.id;
        }
      });
  }

  closeDpaBanner() {
    if (this._lastSownSchoolId) {
      this._bannerService.closeBanner('dpa-' + this._lastSownSchoolId);
    }
  }

  private _showDpaBanner(schoolId: string) {
    if (!schoolId) {
      throw new Error('Can not show dpa banner, School ID is missing');
    }

    this._bannerService.showBanner({
      id: 'dpa-' + schoolId,
      titleKey: 'admin.school.dpa.newVersion.text',
      descriptionKey: 'admin.school.dpa.newVersion.subtitle',
      variant: 'dpa',
      action: {
        link: `${schoolId}/settings/dpa`,
        labelKey: 'admin.school.dpa.newVersion.cta',
      },
    });
  }
}
